import React from 'react';
import TopHeader from './Header/TopHeader';
import Footer from './Header/Footer';

const Layout = ({ children, location }) => {
  return (<>
    <TopHeader />
    <div>{children}</div>
    <Footer />
  </>);
};

export default Layout;