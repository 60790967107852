import * as React from "react";
import WhatsApp from "../../images/svg/whatsapp.svg"
import Telegram from "../../images/svg/telegram.svg";

export const socialArray = [
  { id: "1", link: `https://wa.me/79872136648`, Img: WhatsApp },
  { id: "2", link: `https://t.me/happy_clinic`, Img: Telegram },
];

interface TSocialLink {
  text?: string
}

const SocialLinks = ({ text }: TSocialLink) => {
  return <div className="header__contacts-social__links">
    <span>{text}</span>
    <div className="flex space-x-4 items-center ">
      {socialArray.map(({ link, id, Img }) => {
        return (<a key={id} href={link} target="_blank">
          <Img className="h-6 w-6 lg:h-5 lg:w-5 text-black hover:text-yellow-800"/>
        </a>);
      })}
    </div>
  </div>;
};

export default SocialLinks;