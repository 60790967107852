import * as React from 'react';
import Layout from './src/components/Layout';
import { ApolloWrapper } from './src/apollo/ApolloWrapper';
import './src/styles/global.css';
import { GatsbyBrowser } from 'gatsby';


export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element, props }) => {
  return (
    <ApolloWrapper>
      <Layout location={location}>{element}</Layout>
    </ApolloWrapper>
  );
};
