import React from "react";

export const phonePrefix = "843"
export const phoneNumber = "272-08-72";

const Phone = () => {
  return (
    <div className="header__phone">
      <a href={`tel:+7${phonePrefix}${phoneNumber}`}>
        <div className="flex flex-row-reverse sm:flex-row space-x-1 font-semibold text-black">
          <div className="header__phone-number">
            <span>({phonePrefix})</span>
            {phoneNumber}
          </div>
        </div>
      </a>
    </div>
  );
}

export default Phone