export const beautifyDate = (date: string): string => {
  return new Date(date).toLocaleDateString('ru-RU', {
    month: 'long',
    day: 'numeric',
    weekday: 'long'
  });
}

export const toDate = (date) => new Date(date)

export const isWeekend = (day) => day.getDay() === 0 || day.getDay() === 6;
