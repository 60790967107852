import React from 'react';
import { ScaleLoader } from 'react-spinners';

export const renderTodayInfo = (loading: boolean, isHolidayToday: boolean) => {
  const infoClass: string = isHolidayToday ? 'text-red-500' : 'text-black';
  const infoText: string = isHolidayToday ? 'выходной' : 'работаем с 8:00 до 20:00';
  if (loading) {
    return (
      <div className={`text-stone-800 ${infoClass} py-1 px-2`}>
        <span className={isHolidayToday ? `text-red-500` : null}>Сегодня {infoText}</span>
      </div>
    );
  } else {
    return (
      <div className="flex">
        <ScaleLoader color="white" height={20} width={4} />
      </div>
    );
  }
};