import React, { FC, useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon, ClockIcon } from "@heroicons/react/24/outline";
import { fetchEvents } from "../../api";
import { generateRows } from "../../../data/dataProcessing";
import { renderTodayInfo } from "./CalendarView";
import { toDate } from "../../utils";

const Holidays: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState([]);
  const [isHoliday, setIsHoliday] = useState<boolean>(false)
  const now: Date = new Date();
  const startOfWeek: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - (now.getDay() - 1));
  const endOfWeek: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()));
  const isHolidayToday: boolean = now.getDay() === 6 || now.getDay() === 0 || isHoliday;

  const CALENDAR_URL: string = `https://www.googleapis.com/calendar/v3/calendars/${process.env.GATSBY_GOOGLE_CALEND_ID}/events?key=${process.env.GATSBY_GOOGLE_CALEND_API_KEY}&timeMin=${startOfWeek.toISOString()}&timeMax=${endOfWeek.toISOString()}`;

  const fetchData = async (): Promise<void> => {
    const events = await fetchEvents(CALENDAR_URL);
    setEvents(events);
    setLoading(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rows = generateRows(events, startOfWeek, endOfWeek, now);
  const todayInfo = renderTodayInfo(loading, isHolidayToday);

  useEffect(() => {
    if(events.length > 0) {
      events.map(({ start }) => start.date).forEach(it => {
        const holiday = toDate(it).toDateString();
        setIsHoliday(holiday.includes(now.toDateString()));
      });
    }
  }, [events])

  return (
    <>
      <div className="work__time">
        <Popover className="relative flex">
          <Popover.Button>
            <div className="flex space-x-1 items-center">
              <div className="flex items-center space-x-2">
                <ClockIcon className="h-4 w-4" /> <span>График работы</span>
              </div>
              <ChevronDownIcon className="w-3" />
            </div>
          </Popover.Button>
          <Popover.Panel className="absolute z-10 bg-white rounded-2xl shadow-xl shadow-gray-200 top-5">
            <div className="flex-col p-6 font-light w-max text-gray-800 text-sm">
              {rows}
            </div>
          </Popover.Panel>
        </Popover>
      </div>
      {todayInfo}
    </>
  );
};

export default Holidays;
