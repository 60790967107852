import React from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { Link } from "gatsby";

const navigation = [
  { name: "О нас", href: "/about", current: true },
  { name: "Услуги", href: "/uslugi", current: false },
  { name: "Врачи", href: "/doctors", current: false },
  { name: "Технологии", href: "/technologii", current: false },
  { name: "Цены", href: "/price", current: false },
  { name: "Контакты", href: "/contacts", current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Nav() {
  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="mx-auto sm:px-6 lg:px-8">
            <div className="flex h-10 items-center justify-end">
              <div className="inset-y-0 left-0 flex items-center lg:hidden z-10">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  className="items-center justify-center rounded-md p-2 text-gray-800 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                  ) : (
                    <Bars3Icon className="block h-8 w-8 font-bold" aria-hidden="true"/>
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center justify-center lg:items-stretch lg:justify-start">
                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4 lg:text-sm xl:text-base text-gray-800">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="hover:text-black hover:decoration-white decoration-transparent underline-offset-4 underline decoration-2 px-3 py-2 font-semibold"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="absolute left-0 z-10 w-full lg:hidden bg-amber-200 bg-gray-800">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'text-white' : 'hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-sm font-semibold text-white'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}