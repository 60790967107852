import React from "react";
import { useMediaQuery } from 'react-responsive';
import SocialLinks from "../modules/SocialLinks";
import Holidays from "../modules/Calendar/Holidays";
import Phone from "../modules/Phone";
import Nav from "../modules/Navigation/Nav";
import { MapPinIcon } from "@heroicons/react/24/outline";

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 640 });
  return (
    <header className="header">
      <div className="header__column max-w-7xl mx-auto">
        <div className="header__container">
          <a href="/"><div className="logo__block">
            <div className="logo__block-img">happy clinic</div>
            <span>лазерная стоматология</span>
          </div>
          </a>
          {isMobile && (
            <div className="holidays">
              <Holidays />
            </div>
          )}
          <div className="navigation">
            <Nav/>
          </div>
          <Phone/>
        </div>
        <div className="header__contacts">
          {!isMobile && (
            <div className="holidays">
              <Holidays />
              <div className="header__contacts-addr">
                <MapPinIcon className="h-4 w-4" />
                <span>Сибирский тракт, 3</span>
              </div>
            </div>
          )}
          <div className="header__contacts-social">
            <SocialLinks text="Написать нам"/>
            <Phone/>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
