export const fetchEvents = async (url: string): Promise<any[]> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Ошибка при получении данных из API: ${response.status}`);
    }
    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error(error);
    return [];
  }
};


