import React from "react";
import { beautifyDate, isWeekend, toDate } from "../components/utils";

export const generateRows = (events: any[], startOfWeek: Date, endOfWeek: Date, now: Date): React.ReactNode[] => {
  const daysOnWeek: Date[] = [];
  for (let date = toDate(startOfWeek); date <= endOfWeek; date.setDate(date.getDate() + 1)) {
    daysOnWeek.push(toDate(date));
  }

  let isHolidayToday = false;

  return daysOnWeek.map((day) => {
    const dayString = day.toDateString();
    const holidays = events.map(({ start }) =>
      toDate(start?.date).toDateString()
    );
    const isHolidayCurrent = holidays.includes(dayString);

    if (now.toDateString() === dayString) {
      isHolidayCurrent ? (isHolidayToday = true) : null;
    }

    return (
      <div key={dayString} className="flex justify-between space-x-4">
        <div>
          {beautifyDate(dayString) === beautifyDate(now.toDateString()) ? (
            <span className="text-blue-500 underline font-semibold">
              {beautifyDate(dayString)}
            </span>
          ) : (
            beautifyDate(dayString)
          )}
        </div>
        <div>
          {isHolidayCurrent || isWeekend(day) ? (
            <span className="text-red-400 font-semibold">Выходной</span>
          ) : (
            "08:00 – 20:00"
          )}
        </div>
      </div>
    );
  });
};
