exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-doctors-tsx": () => import("./../../../src/pages/doctors.tsx" /* webpackChunkName: "component---src-pages-doctors-tsx" */),
  "component---src-pages-implantation-tsx": () => import("./../../../src/pages/implantation.tsx" /* webpackChunkName: "component---src-pages-implantation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-technologii-tsx": () => import("./../../../src/pages/technologii.tsx" /* webpackChunkName: "component---src-pages-technologii-tsx" */),
  "component---src-pages-uslugi-tsx": () => import("./../../../src/pages/uslugi.tsx" /* webpackChunkName: "component---src-pages-uslugi-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/BlogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-page-detail-tsx": () => import("./../../../src/templates/PageDetail.tsx" /* webpackChunkName: "component---src-templates-page-detail-tsx" */),
  "component---src-templates-technology-page-tsx": () => import("./../../../src/templates/TechnologyPage.tsx" /* webpackChunkName: "component---src-templates-technology-page-tsx" */)
}

